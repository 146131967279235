import "~/src/global"

type ShadowRealmDocuments = {
  querySelector: typeof document.querySelector
}

declare global {
  interface Window {
    shadowRealms?: ShadowRealmDocuments
  }
}

// Maps DOM manipulation methods to the shadow root of all <shadow-realm>'s in the document.
const shadowRealms: ShadowRealmDocuments = {
  querySelector: (selectors: Parameters<typeof document.querySelector>[0]) => {
    for (const node of document.querySelectorAll("shadow-realm")) {
      const targetNode = node?.shadowRoot?.querySelector(selectors)

      if (targetNode) return targetNode
    }
  },
}

/**
 * Custom element that creates a shadow root, moves its children to it and prepends any preloaded style links
 * in `<head />` marked with the attribute `shadow-realm`. This allows descendants to use new styles unencumbered
 * by those applied globally.
 */
export class ShadowRealm extends HTMLElement {
  styleNode: HTMLStyleElement

  static define() {
    window.shadowRealms = shadowRealms
    customElements.get("shadow-realm") || customElements.define("shadow-realm", this)
  }

  constructor() {
    super()
    this.attachShadow({ mode: "open" })
  }

  async connectedCallback() {
    if (!this.shadowRoot) return

    // Attach preloaded stylesheets marked with `shadow-realm` attribute to the
    // shadow root.
    const sheetLinks = document.querySelectorAll(`head > link[shadow-realm]`)

    sheetLinks.forEach((sheetLink) => {
      if (sheetLink instanceof HTMLLinkElement) {
        const link = document.createElement("link")
        link.rel = "stylesheet"
        link.href = sheetLink.href

        this.shadowRoot?.prepend(link)
      }
    })

    const wrapper = document.createElement("div")
    wrapper.classList.add("tailwind-scope")

    this.shadowRoot.append(wrapper)

    await this._mountDescendents(wrapper)

    if (window.Alpine) {
      // This casting is innacute, because this.shadowRoot is a ShadowRoot which
      // is not a HTMLElement. However, @types/alpinejs@3.13.6 suddenly decided
      // that the first argument must be HTMLElement, yet a ShadowRoot is
      // still supported. Previously, it expected a Node which is a an
      // ancestor of both HTMLElement and ShadowRoot.
      window.Alpine.initTree(this.shadowRoot as unknown as HTMLElement)
    }
  }

  _nonSlotChildNodes(): Node[] {
    return Array.from(this.childNodes).filter((node) => {
      if (node instanceof HTMLElement) {
        return node.getAttribute("slot") == null
      } else {
        return true
      }
    })
  }

  // Fancy magic to move the children to the shadow root as soon they're parsed
  // avoiding any paint jitter before render.
  _mountDescendents(parentNode): Promise<void> {
    return new Promise((resolve) => {
      const nodes = this._nonSlotChildNodes()
      if (nodes.length > 0) {
        parentNode.append(...nodes)
        resolve()
      } else {
        const observer = new MutationObserver(() => {
          const nodes = this._nonSlotChildNodes()
          parentNode.append(...nodes)
          resolve()
          observer.disconnect()
        })
        observer.observe(this, { childList: true })
      }
    })
  }
}
